import 'bulma/css/bulma.min.css';
import { useState } from 'react';
import './App.css';

function App() {

  const [description, setDescription] = useState('')
  const [dimensions, setDimensions] = useState('')
  const [price, setPrice] = useState('')
  const [manufacturer, setManufacturer] = useState('Artesã: Cristina Saraiva')
  const sendMessage = () => {
    var encodedURL = encodeURIComponent(getFormatValue());
    return `whatsapp://send?text=${encodedURL}`;
  }

  const checkField = (label, field) => {
    return `${field != '' ? ''+ label + field + '\n' : ''}`
  }

  const getFormatValue = () => {
    return `${checkField('', description)}${checkField('Dimensões: ', dimensions)}${checkField('Valor: R$ ', price)}${description != '' ? manufacturer : ''}`
  }

  const resetForm = () => {
    setDescription('')
    setDimensions('')
    setPrice('')
  }

  return (
    <div className="root">
      <div className="container is-widescreen">
        <div className="field">
          <label className="label">Descrição do produto</label>
          <div className="control">
            <input onChange={(e) => {
              setDescription(e.target.value)
            }}
              value={description} className="input" type="text" placeholder="Digite a descrição" />
          </div>
          {/* <p className="help">This is a help text</p> */}
        </div>

        <div className="field">
          <label className="label">Medidas</label>
          <div className="control">
            <input onChange={(e) => {
              setDimensions(e.target.value)
            }}
              value={dimensions}
              className="input" type="text" placeholder="33cm" />
          </div>
          {/* <p className="help">This is a help text</p> */}
        </div>

        <div className="field">
          <label className="label">Valor</label>
          <div className="control">
            <input onChange={(e) => {
              setPrice(e.target.value)
            }} 
            value={price}
            className="input" type="number" placeholder="R$" />
          </div>
          {/* <p className="help">This is a help text</p> */}
        </div>


        <div className="field">
          <label className="label">Como será compartilhado</label>
          <div className="control">
            <textarea disabled value={getFormatValue()} className="textarea" rows="4"></textarea>
          </div>
          {/* <p className="help">This is a help text</p> */}
        </div>
        <br />

        <div className="is-flex is-justify-content-space-between	">
          <a className="button is-primary" href={sendMessage()}>Enviar no WhatsApp</a>
          <button class="button is-danger is-focused" onClick={() => resetForm()}>Limpar</button>
        </div>

      </div>
    </div>
  );
}

export default App;
